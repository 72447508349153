import { DocumentType, EspaceType } from '@innedit/innedit-type';
import { EspaceData } from 'packages/innedit';
import { useEffect, useState } from 'react';

import { ActionProps } from '~/components/Actions/props';
import IconFile from '~/icons/File';
import IconGlobe from '~/icons/Globe';

const useEspace = ({
  id,
  pathname,
}: {
  id: string;
  pathname: string;
}): [
  DocumentType<EspaceType> | undefined,
  (ActionProps | ActionProps[])[] | undefined,
] => {
  const [espace, setEspace] = useState<DocumentType<EspaceType>>();

  useEffect(() => {
    let isMounted = true;

    const espaceModel = new EspaceData();
    espaceModel.watchById(id, snapshot => {
      if (isMounted) {
        setEspace(snapshot);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [id]);

  if (!espace) {
    return [undefined, undefined];
  }

  const isActive = (to: string): string | undefined =>
    pathname.slice(0, `/admin/espaces/${id}/${to}`.length) ===
    `/admin/espaces/${id}/${to}`
      ? 'is-active'
      : undefined;

  return [
    espace,
    [
      {
        className: isActive(`update`),
        icon: IconFile,
        label: 'Paramètres',
        to: `/admin/espaces/${id}/update`,
      },
      {
        className: isActive(`channels`),
        icon: IconGlobe,
        label: 'Canaux',
        to: `/admin/espaces/${id}/channels`,
      },
    ],
  ];
};

export default useEspace;
