import { navigate, PageProps } from 'gatsby';
import { ChannelData } from 'packages/innedit';
import React, { FC, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '~/components/List/Item/Channel';
import List from '~/containers/Espace/List';
import IconChevronLeft from '~/icons/ChevronLeft';
import params from '~/params/espace.json';
import TemplateAdmin from '~/templates/admin';
import requireUser, { UserProps } from '~/utils/requireUser';
import useEspace from '~/utils/useEspace';

const AdminEspaceChannel: FC<PageProps & UserProps> = ({
  location,
  user,
  params: { id: espaceId },
}) => {
  const { t } = useTranslation();
  const [espace, items] = useEspace({
    id: espaceId,
    pathname: location.pathname,
  });

  const handleCloseOnClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    return navigate(-1);
  };

  return (
    <TemplateAdmin>
      <List
        itemList={Item}
        menu={{
          left: [
            {
              icon: IconChevronLeft,
              label: 'Revenir à la page précédente',
              onClick: handleCloseOnClick,
            },
          ],
        }}
        model={new ChannelData({ espaceId, params, wheres: { parent: '' } })}
        search={location.search}
        subMenu={items}
        title={espace?.label ?? t('channels.title')}
        user={user}
      />
    </TemplateAdmin>
  );
};

export default requireUser(AdminEspaceChannel, 'admin');
